import React, { useState } from 'react'
import { trans } from '@taghub/translations';
import Input from '../../Input'
import Error from '../../Error'
import Buttons from './Buttons'
import { OTPIcon } from '../../icons'
import './index.css'

export default ({ username, password, onVerified, userMFAList = [] }) => {
    const [selectdOption, setSelectdOption] = useState()
    const [mfakey, setMfakey] = useState('')
    const [error, setError] = useState('')

    const onOtpChange = e => {
        setError('')
        setMfakey(e.target.value)
    }

    const onVerifyOTP = async () => {
        let body = JSON.stringify({ username, password, mfakey, mfaid: selectdOption.id })
        let response = await fetch('/auth', { method: 'POST', body })
        if (response.status === 200) {
            setError(' ')
            setSelectdOption()
            onVerified && onVerified()
        }
        else {
            let { message, tag } = await response.json()
            setError(tag || message)
        }

    }
    const onSendOTP = async value => {
        const mfaid = value ? value.id : selectdOption.id
        let body = JSON.stringify({ username, password, mfaid })
        let response = await fetch('/auth/mfa/code', { method: 'POST', body })
        if (response.status === 200) {
            setError(' ')
        }
        else {
            let { message, tag } = await response.json()
            setError(tag || message)
        }
    }
    const onSelectMFAOption = async value => {
        setError(' ')
        setSelectdOption(value)
        onSendOTP(value)
    }
    function onBackOption () {
      setError(' ')
      setSelectdOption()
    }

  return (
    <div className={'Taghub-Login__MFA'}>
      <h4 className="Taghub-Login__OTPTitle">{trans('LOGIN_OTP_TITLE')}</h4>
      {
        !selectdOption && <>
          <div className="Taghub-Login__OTPSubTitle">
            {trans('LOGIN_OTP_SELECT_PREFERRED_OPTION')}
          </div>
          {
            userMFAList.map(mfa =>
              mfa.active && mfa.type === 'email'
                ? <label key={mfa.type} className='Taghub-Login__Radio'>
                  <input
                    type="radio"
                    name='otpType'
                    value={mfa.type}
                    onChange={() => onSelectMFAOption(mfa)}
                  />
                  <div>{trans('LOGIN_OTP_OPTION_EMAIL')}</div>
                </label>
                : mfa.active && mfa.type === 'phone'
                  ? <label key={mfa.type} className='Taghub-Login__Radio'>
                    <input
                      type="radio"
                      name='otpType'
                      value={mfa.type}
                      onChange={() => onSelectMFAOption(mfa)}
                    />
                    <div>{trans('LOGIN_OTP_OPTION_PHONE')}</div>
                  </label>
                  : null
            )
          }
        </>
      }

      {
        selectdOption && <>
          <div className="Taghub-Login__Back"
            onClick={onBackOption}
          >
            {trans('LOGIN_OTP_BACK_OPTION')}
          </div>
          <Input
            type='text'
            placeholder={trans('LOGIN_OTP_INPUT_PLACEHOLDER')}
            value={mfakey}
            Icon={OTPIcon}
            onChange={onOtpChange}
          />
          <Buttons
            onSendOTP={onSendOTP}
            onVerifyOTP={onVerifyOTP}
            mfakey={mfakey}
          />
        </>
      }
      <Error error={error} />
    </div>
  )
}
