import React, { useEffect, useState } from 'react'
import { trans } from '@taghub/translations';

export default ({ onSendOTP, onVerifyOTP, mfakey }) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setCounter(30)
    return () => setCounter(0)
  }, []);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const _onSendOTP = () => {
    setCounter(30)
    onSendOTP && onSendOTP()
  }

  return (
    <div className='Taghub-Login__LoginForm__Buttons'>
      <button disabled={!mfakey} className='Taghub-Login__Buttons_SubmitOTP' onClick={onVerifyOTP} >
        {trans('LOGIN_OTP_SUBMIT')}
      </button>
      <button className='Taghub-Login__Buttons_ResendOTP primary' disabled={counter > 0} onClick={_onSendOTP} >
        {counter > 0 && counter} {trans('LOGIN_OTP_RESEND')}
      </button>
    </div>
  )
}
