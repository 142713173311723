import React from 'react'
import { Translate } from '@taghub/translations';
import './index.css'

export default ({ onBack }) => 
    <div className='Taghub-Login CheckEmail'>
        <div className='CheckEmailText'>Check your email :)</div>
        <div className='Taghub-Login__Back' onClick={onBack}>
          <a className='Taghub-Login__Link' href='#'><Translate tag='COMPONENT_LOGIN_BACK_TO_LOGIN'> <div>Back to Login</div> </Translate></a>
        </div>
    </div>
