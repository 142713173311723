import React, { useState } from 'react'
import { useTranslation, trans } from '@taghub/translations';
import store from 'store'
import classnames from 'classnames'
import queryString from 'query-string'
import Input from '../Input'
import Confirm from '../Confirm'
import Error from '../Error'
import Buttons from './Buttons'
import Options from '../Options'
import { AccountIcon, PasswordIcon } from '../icons'
import './index.css'

export default ({ onLoggedIn, onEmailMode, onSsoMode, onSignUp, redirectUrl, autoFocus, cardIdType='text' }) => {
    const { t } = useTranslation()
    let search = queryString.parse(window.location.search)
    const [ cardId, setCardId ] = useState('')
    const [ pin, setPin ] = useState(store.get('pin') || '')
    const [ rememberPin, setRememberPin ] = useState(!!store.get('pin') || false)
    const [ confirm, setConfirm ] = useState(search.confirm)
    const [ error, setError ] = useState('')
    const onCardIdChange = e => {
        setError('')
        setConfirm('')
        setCardId(e.target.value)
    }
    const onPinChange = e => {
        setError('')
        setConfirm('')
        setPin(e.target.value)
    }
    const onRememberPinChange = e => setRememberPin(e.target.checked)
    const onKeyUp = e => {
        if (e.keyCode === 13) {
            onLogin()
        }
    }
    const onLogin = async () => {
        if (!cardId || !pin) {
            setError(' ')
            return
        }
        let body = JSON.stringify({ cardId, pin })
        let response = await fetch('/auth/card', { method: 'POST', body })
        if (response?.status === 200) {
            store.set('pin', rememberPin ? pin : '')
            window.location = redirectUrl
        }
        else {
           // let { message } = await response.json()
            setError(trans('COMPONENT_LOGIN_CARD_ERROR'))
        }
    }
    const classes = classnames({
        'Taghub-Login': true,
        error: !!error
    })
    return (
        <div className={classes}>
            <Input 
                type={cardIdType} 
                placeholder={trans('COMPONENT_LOGIN_CARD_ID')}
                value={cardId}
                Icon={AccountIcon} 
                autoFocus={autoFocus}
                autoComplete='new-password'
                onKeyUp={onKeyUp}
                onChange={onCardIdChange}
                />
            <Input 
                type='password' 
                placeholder='PIN' 
                value={pin}
                Icon={PasswordIcon} 
                autoComplete='new-password'
                onChange={onPinChange}
                onKeyUp={onKeyUp}
                />
            <Buttons 
                onLogin={onLogin}
                onSignUp={onSignUp}
                />
            <Error error={error} />
            { 
                confirm && 
                <Confirm code={confirm} />
            }
            <Options 
                rememberPin={rememberPin}
                onRememberPinChange={onRememberPinChange}
                onEmailMode={onEmailMode}
                onSsoMode={onSsoMode}
                />
        </div>
    )
}
