import React from 'react'
import { trans } from '@taghub/translations';
import './index.css'

export default ({ error }) =>
    error.trim() && 
    <div className='Taghub-Login__Error'>
        {
            error?.startsWith('LOGIN_')
            ? trans(error)
            : <>{error}</>
        }
    </div> 