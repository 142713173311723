import React, { Fragment } from 'react'
import { Translate } from '@taghub/translations';
import { EmailIcon, PinIcon, SsoIcon } from '../icons'
import './index.css'

export default function Options({ rememberPin, onBackToLogin, onRememberPinChange, onEmailMode, onPinMode, onSsoMode, onForgot }) {
  return (
    <div className='Taghub-Login__Options'>
      <div className='Taghub-Login__Options__Left'>
        { onForgot &&
          <div className='Taghub-Login__Forgot' onClick={onForgot}>
            <a className='Taghub-Login__Link' href='#/forgot'><Translate tag='COMPONENT_LOGIN_FORGOT_PASSWORD'> <div>Forgot Password?</div></Translate></a>
          </div>
        }
        { onBackToLogin &&
          <div className='Taghub-Login__BackToLogin' onClick={onBackToLogin}>
            <a className='Taghub-Login__Link' href='#'><Translate tag='COMPONENT_LOGIN_BACK_TO_LOGIN'> <a className='Taghub-Login__Link' href='#'>Back to Login</a></Translate></a>
          </div>
        }
        { onRememberPinChange &&
          <Fragment>
            <label className='Taghub-Login__RememberPin'>
              <input type='checkbox' defaultChecked={rememberPin} onClick={onRememberPinChange} />
              <Translate tag='COMPONENT_LOGIN_REMEMBER_PIN'> <div>Remember PIN</div></Translate>
            </label>
          </Fragment>
        }
      </div>
      <div className='Taghub-Login__Options__Right'>
        { onEmailMode &&
          <EmailIcon onClick={onEmailMode} />
        }
        { onPinMode &&
          <PinIcon onClick={onPinMode} />
        }
        { onSsoMode &&
          <SsoIcon onClick={onSsoMode} />
        }
      </div>
    </div>
  )
}