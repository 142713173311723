import React, { useState } from 'react'
import { useTranslation, trans, Translate } from '@taghub/translations';
import classnames from 'classnames'
import queryString from 'query-string'
import Input from '../Input'
import Confirm from '../Confirm'
import Error from '../Error'
import Buttons from './Buttons'
import Options from '../Options'
import MFAOptions from './MFAOptions'
import { AccountIcon, PasswordIcon } from '../icons'
import './index.css'

export default ({ onLoggedIn, onPinMode, onSsoMode, onForgot, onSignUp, language, autoFocus }) => {
    const { t } = useTranslation()
    let search = queryString.parse(window.location.search)
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ confirm, setConfirm ] = useState(search.confirm)
    const [ error, setError ] = useState('')
    const [ userMFAList, setUserMFAList ] = useState()
    const onUsernameChange = e => {
        setError('')
        setConfirm('')
        setUserMFAList()
        setUsername(e.target.value)
    }
    const onPasswordChange = e => {
        setError('')
        setConfirm('')
        setUserMFAList()
        setPassword(e.target.value)
    }
    const onKeyUp = e => {
        if (e.keyCode === 13) {
            onLogin()
        }
    }
    const onVerified = () => {
      setUserMFAList()
      onLoggedIn()
    }
    const onLogin = async () => {
        if (!username || !password) {
            setUserMFAList()
            setError(' ')
            return
        }
        const query = `username=${username}&password=${password}`
        let res = await fetch(`/auth/mfa/code?${query}`)
        const { data } = await res.json()
        if(data?.length > 0){
            setUserMFAList(data)
        }else{
            setUserMFAList()
            without2FALogin()
        }
    }
    const without2FALogin = async () => {
        let body = JSON.stringify({ username, password })
        let response = await fetch('/auth', { method: 'POST', body })
        if (response.status === 200) {
            onLoggedIn()
        }
        else {
            let { message, tag } = await response.json()
            setError(tag || message)
        }
    }
    const classes = classnames({
        'Taghub-Login': true,
        error: !!error
    })
    return (
        <div className={classes}>
        {
          !userMFAList && <>
            <Input
              type='email'
              placeholder={trans('COMPONENT_LOGIN_EMAIL')}
              value={username}
              Icon={AccountIcon}
              onKeyUp={onKeyUp}
              autoFocus={autoFocus}
              onChange={onUsernameChange}
            />
            <Input
              type='password'
              placeholder={trans('COMPONENT_LOGIN_PASSWORD')}
              value={password}
              Icon={PasswordIcon}
              onChange={onPasswordChange}
              onKeyUp={onKeyUp}
            />
            <Buttons
              onLogin={onLogin}
              onSignUp={onSignUp}
              language={language}
            />

            {
              confirm &&
              <Confirm code={confirm} />
            }
            <Error error={error} />
          </>
        }
        {userMFAList &&
          <MFAOptions
            userMFAList={userMFAList}
            onVerified={onVerified}
            username={username}
            password={password}
          />
        }
            
        <Options
          onPinMode={onPinMode}
          onSsoMode={onSsoMode}
          onForgot={onForgot}
          language={language}
        />
        </div>
    )
}
