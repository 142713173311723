import React, { useState, Suspense, useEffect } from 'react'
import { initi18n, changeLanguage, initTranslation } from '@taghub/translations';
import PropTypes from 'prop-types'
import store from 'store'
import LoginForm from './LoginForm'
import PinForm from './PinForm'
import SsoForm from './SsoForm'
import ForgotForm from './ForgotForm'
import CheckEmail from './CheckEmail'
import Loader from './Loader'
import { MODE } from './constants'
import './index.css'

function Login({ 
  defaultMode, 
  onLoggedIn, 
  onSignUp, 
  language='en', 
  redirectUrl='/index', 
  autoFocus=false, 
  cardIdType, 
  defaultIsForgotVisible=false
}) {
    
  const [ mode, setMode ] = useState(defaultMode || store.get('mode') || MODE.EMAIL)
  const [ forgot, setForgot ] = useState(defaultIsForgotVisible)
  const [ checkEmail, setCheckEmail ] = useState(false)
  const [ loaded, setLoaded ] = useState(false)

  useEffect(() => {
    const loadtransLation = async () => {
      //await initi18n({react: {useSuspense: false }}) //only active if like remove Suspense   
      await initTranslation()
      changeLanguage(language)
      setLoaded(true)
    }
    loadtransLation()
  }, [])

  const onReset = () => setCheckEmail(true)
  const onBack = event => {
      event.preventDefault()
      setForgot(false)
      setCheckEmail(false)
  }
  const onPinMode = () => {
      store.set('mode', MODE.PIN)
      setMode(MODE.PIN)
  }
  const onEmailMode = () => {
      store.set('mode', MODE.EMAIL)
      setMode(MODE.EMAIL)
  }
  const onSsoMode = () => {
      store.set('mode', MODE.SSO)
      setMode(MODE.SSO)
  }
  const onForgot = event =>{
    event.preventDefault()
    setForgot(true)
  }
  
  return (
    <Suspense>
      {
      !loaded 
      ? <Loader />
      : checkEmail 
      ? <CheckEmail onBack={onBack} />
      : forgot 
      ? <ForgotForm 
          onReset={onReset}
          onBack={onBack} 
          autoFocus={autoFocus}
          />
      : mode === MODE.PIN 
      ? <PinForm 
          onEmailMode={onEmailMode}
          onSsoMode={onSsoMode}
          onSignUp={onSignUp} 
          redirectUrl={redirectUrl}
          autoFocus={autoFocus}
          cardIdType={cardIdType}
          />
      : mode === MODE.SSO
      ? <SsoForm
          onEmailMode={onEmailMode}
          onPinMode={onPinMode}
          autoFocus={autoFocus}
      />
      : <LoginForm 
          onLoggedIn={onLoggedIn}
          onPinMode={onPinMode}
          onSsoMode={onSsoMode}
          onForgot={onForgot}
          onSignUp={onSignUp}
          redirectUrl={redirectUrl}
          autoFocus={autoFocus}
          />
      }
    </Suspense>
  )
}

Login.propTypes = {
  defaultMode: PropTypes.oneOf(Object.keys(MODE).map(key => key.toLowerCase())),
  redirectUrl: PropTypes.string,
  language: PropTypes.string,
  defaultIsForgotVisible: PropTypes.bool,
  onLoggedIn: PropTypes.func,
  onSignUp: PropTypes.func,
}

export default Login
