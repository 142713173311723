import React, { useState } from 'react'
import Buttons from './Buttons'
import Options from '../Options'
import './index.css'

export default ({ onEmailMode, onPinMode, autoFocus }) => {
  const [ company, setCompany ] = useState('swire')
  
  const onChange = event => setCompany(event.target.value)
  const onSubmit = () => window.location = `/sso/${company}`

  const selectRef = React.useRef();
	React.useEffect(() => {
		if (autoFocus && selectRef?.current) {
			selectRef.current.focus();
		}
	}, []);


  return (
    <div className='Taghub-Login'>
      <select value={company} onChange={onChange} ref={selectRef}>
        <option value='swire'>Swire</option>
        <option value='equinor'>Equinor</option>
      </select>
      <Buttons onSubmit={onSubmit} />
      <Options 
        onEmailMode={onEmailMode}
        onPinMode={onPinMode}
      />
    </div>
  )
}
