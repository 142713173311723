import React, { useState } from 'react'

export default ({ Icon,autoFocus, ...rest }) => {

    let [className, setClassName] = useState('')

    const onFocus = () => setClassName('focus')
    const onBlur  = () => setClassName('')
    
    const inputRef = React.useRef();
    React.useEffect(() => {
        if(autoFocus && inputRef?.current){
           inputRef.current.focus();
        }
      }, [])


    return (
        <div className={'Input '+className}>
            <Icon />
            <input 
                {...rest} 
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputRef}
                />
        </div>
    )
}
