import React from 'react'

const AccountIcon = props => 
    <svg viewBox="0 0 24 24" {...props}>
        <path d="M11.9 2c1 0 1.4.2 1.5.3l.5.6.7.1c.5.1.8.3 1 .5.2.3.4.7.4 1.3.1.9-.1 1.9-.4 3l-.3 1.1.6.7v.2l-.9.4-.2 1c-.1.4-.2.8-.3 1.1-.1.1-.1.2-.2.3-.1 0-.1.1-.2.2l-.6.6v.9c0 .7 0 1.3.1 2.2v.3l.1.3c.5 1.1 1.6 1.7 2.4 2.1.5.3 1.1.5 1.8.7.3.1.7.3 1.1.4.7.3 1.6.7 2.2 1.3.1.1.2.3.3.4H2.6c.1-.1.2-.3.3-.4.6-.6 1.5-1 2.2-1.3.4-.1.7-.3 1.1-.4.6-.2 1.2-.5 1.8-.7.8-.4 1.8-1 2.4-2.1l.1-.3v-.3c.1-.8.1-1.5.1-2.2v-.8l-.6-.7-.2-.2c-.1-.1-.1-.2-.2-.3-.1-.3-.3-.7-.3-1.1l-.2-1-.9-.4v-.2l.6-.7-.3-1C8 6 8 4.5 8.4 3.6 9 2.3 10.7 2 11.9 2m0-2C9.1 0 7.3 1.1 6.6 2.8c-.8 1.5-.6 3.5-.1 5.6-.2.3-.5.7-.4 1.4.1.7.3 1.2.6 1.5.2.2.4.2.6.3.1.6.3 1.2.5 1.7.2.3.3.5.5.8.1.1.2.1.3.2 0 .7 0 1.3-.1 2-.2.4-.6.8-1.4 1.1-.8.4-1.7.7-2.7 1.1s-2 .9-2.8 1.7C.7 20.9.1 22 0 23.4v.6h24v-.6c-.1-1.4-.7-2.5-1.5-3.2-.8-.8-1.8-1.3-2.8-1.7-1-.4-2-.7-2.7-1.1-.7-.3-1.2-.7-1.4-1.1-.1-.7-.1-1.3-.1-2 .1-.1.2-.1.3-.2.2-.2.3-.5.5-.8.3-.5.4-1.1.5-1.7.2-.1.4-.1.5-.3.3-.3.5-.8.6-1.5.1-.6-.1-1-.4-1.3.3-.9.7-2.3.5-3.8 0-.9-.3-1.7-.8-2.4-.5-.6-1.2-1.1-2.2-1.2C14.4.3 13.2 0 11.9 0z"/>
    </svg>

const PasswordIcon = props => 
    <svg viewBox="0 0 24 24" {...props}>
        <path d="M19 8h-1V3c0-1.7-1.3-3-3-3H9C7.3 0 6 1.3 6 3v5H5c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V11c0-1.7-1.3-3-3-3zM8 3c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v5H8V3zm12 18c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1V11c0-.6.4-1 1-1h14c.6 0 1 .4 1 1v10z"/>
        <path d="M13 14v4h-2v-4h2m1-1h-4v6h4v-6z"/>
    </svg>

const PinIcon = props => 
    <svg className='PinIcon' viewBox="0 0 512 512" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" {...props}>
        <path d="M49 119h421c13 0 24 11 24 24v210c0 13-11 24-24 24H49c-13 0-24-11-24-24V143c0-13 11-24 24-24zm116 97l-23 20v-17l22-18h14v94h-14v-79zm82 34l11-7c5-3 9-7 11-9 2-3 3-6 3-9 0-4-2-7-5-10s-7-4-11-4c-3 0-7 1-11 3s-9 4-14 8v-15c4-2 8-4 12-5s9-2 13-2c9 0 17 2 22 7s8 11 8 19c0 5-1 10-4 14s-8 8-14 13l-9 6c-6 4-10 8-13 11-2 3-3 7-3 12v3h42v11h-56v-2-3c0-10 1-18 4-23 3-6 8-11 15-16zm69 29c4 2 8 4 12 5s8 2 12 2c7 0 12-2 16-5 4-4 6-8 6-14s-2-11-6-13c-4-3-11-4-20-4h-5v-9l25-26h-40v-11h56v13l-24 24c9 0 15 3 20 8s7 12 7 20c0 9-3 17-10 22s-15 8-27 8c-4 0-7 0-11-1s-8-2-12-4v-14zm166-2V149c0-10-8-19-19-19H54c-10 0-19 8-19 19v200c0 10 8 19 19 19h409c10 0 19-8 19-19v-72z" />
    </svg>

const EmailIcon = props => 
    <svg className='EmailIcon' viewBox="160 160 192 192" {...props}>
        <rect fill="none" height="110" stroke="#59d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5" width="184.5" x="163.8" y="201"/>
        <polyline fill="none" points="   163.8,201 251.2,256 348.2,201  " stroke="#59d" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="5"/>
    </svg>

const SsoIcon = props => 
    <div className='SsoIcon' style={{ 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid rgb(85, 153, 221, 0.8)',
        borderRadius: '2px',
        color: 'var(--blue-mid)',
        boxSizing: 'border-box',
    }} {...props}>
        SSO
    </div>

const OTPIcon = props => <svg className="OTPIcon" {...props}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.22 122.88"><path  style={{fillRule:'evenodd' }} d="M12.71,0h43A12.74,12.74,0,0,1,68.43,12.71V31.52H64.78V16H3.66v89.07H64.79V95.22l3.65-3v17.93a12.74,12.74,0,0,1-12.71,12.71h-43A12.74,12.74,0,0,1,0,110.17V12.71A12.74,12.74,0,0,1,12.71,0ZM36.4,52a1.85,1.85,0,0,1,3.69,0v2.38l2-1.32a1.83,1.83,0,1,1,2,3l-2.54,1.71,2.55,1.69a1.84,1.84,0,0,1-2,3.06l-2-1.32v2.39a1.85,1.85,0,0,1-3.69,0V61.27l-2,1.32a1.83,1.83,0,1,1-2-3.05l2.55-1.7-2.55-1.7a1.83,1.83,0,1,1,2-3.06l2,1.32V52Zm38.73,0a1.84,1.84,0,0,1,3.68,0v2.38l2-1.32a1.83,1.83,0,0,1,2,3l-2.55,1.71,2.55,1.69a1.84,1.84,0,1,1-2,3.06l-2-1.32v2.39a1.84,1.84,0,1,1-3.68,0V61.27l-2,1.32a1.84,1.84,0,1,1-2-3.05l2.55-1.7-2.55-1.7a1.84,1.84,0,1,1,2-3.06l2,1.32V52ZM55.77,52a1.84,1.84,0,0,1,3.68,0v2.38l2-1.32a1.84,1.84,0,0,1,2,3l-2.55,1.71,2.55,1.69a1.84,1.84,0,1,1-2,3.06l-2-1.32v2.39a1.84,1.84,0,0,1-3.68,0V61.27l-2,1.32a1.83,1.83,0,0,1-2-3.05l2.55-1.7-2.55-1.7a1.84,1.84,0,1,1,2-3.06l2,1.32V52ZM26.55,39.11H88.17a5.08,5.08,0,0,1,5,5.06V71.24a5.26,5.26,0,0,1-5,5.26H66.53C61,83.51,53.79,87.73,45.27,90a1.89,1.89,0,0,1-1.88-.56,1.86,1.86,0,0,1,.15-2.64,20.35,20.35,0,0,0,2.74-2.9,25.27,25.27,0,0,0,3.45-7.6H26.55a5.09,5.09,0,0,1-5.06-5.06V44.17a5.07,5.07,0,0,1,5.06-5.06ZM34.22,109A5.21,5.21,0,1,1,29,114.25,5.22,5.22,0,0,1,34.22,109Z"/></svg>
export { AccountIcon, PasswordIcon, PinIcon, EmailIcon, SsoIcon, OTPIcon }
