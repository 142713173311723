import React from 'react'
import { Translate } from '@taghub/translations';
import './index.css'

export default ({ onLogin, onSignUp, language }) => 
    <div className='Taghub-Login__LoginForm__Buttons'>
        <button className='Taghub-Login__Buttons__Login' onClick={onLogin}>
          <Translate tag='COMPONENT_LOGIN_LOGIN'> <div>Login</div> </Translate>
        </button>
        <button className='Taghub-Login__Buttons__Signup' onClick={onSignUp}>
         <Translate tag='COMPONENT_LOGIN_SIGN_UP'> <div>Sign Up</div> </Translate>
        </button>
    </div>