import React, { useState } from 'react'
import { useTranslation, trans } from '@taghub/translations';
import classnames from 'classnames'
import Input from '../Input'
import Error from '../Error'
import Buttons from './Buttons'
import Options from '../Options'
import { AccountIcon, PasswordIcon } from '../icons'
import './index.css'

export default ({ onReset, onBack, autoFocus }) => {
    const { t } = useTranslation()
    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')
    const onUsernameChange = e => {
        setError('')
        setUsername(e.target.value)
    }
    const onPasswordChange = e => {
        setError('')
        setPassword(e.target.value)
    }
    const onKeyUp = e => {
        if (e.keyCode === 13) {
            onGetNewPassword()
        }
    }
    const onGetNewPassword = async () => {
        if (!username || !password) {
            setError(' ')
        }
        let body = JSON.stringify({ username, password })
        let response = await fetch('/password/', { method: 'POST', body })
        if (response.status === 200) {
            onReset()
        }
        else {
            let { message, tag } = await response.json()
            setError(tag || message)
        }
    }
    const classes = classnames({
        'Taghub-Login': true,
        error: !!error
    })
    return (
        <div className={classes}>
            <Input 
                type='email' 
                placeholder={trans('COMPONENT_LOGIN_EMAIL')}
                value={username}
                Icon={AccountIcon} 
                autoFocus={autoFocus}
                onKeyUp={onKeyUp}
                onChange={onUsernameChange}
                />
            <Input 
                type='password' 
                placeholder={trans('COMPONENT_LOGIN_NEW_PASSWORD')}
                value={password}
                Icon={PasswordIcon} 
                onChange={onPasswordChange}
                onKeyUp={onKeyUp}
                />
            <Buttons 
                onGetNewPassword={onGetNewPassword}
            />
            <Error error={error} />
            <Options 
                onBackToLogin={onBack} 
                />
        </div>
    )
}
