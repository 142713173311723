import React, { useState, useEffect } from 'react'
import './index.css'

export default ({ code }) => {

    const [info, setInfo] = useState('')

    const confirm = async code => {
        let body = JSON.stringify({ confirmationString: code })
        let response = await fetch('/confirm/', { method: 'POST', body })
        let { message } = await response.json()
        setInfo(message)
    }

    useEffect(() => {
        if (code) {
            confirm(code)
        }
    }, [])

    return (
        <div className='Taghub-Login__Confirm'>
            { info }
        </div> 
    )
}
